import React, { Dispatch, SetStateAction } from "react";
import { Box, Paper, Toolbar } from "@material-ui/core";
import { DateRange } from "@material-ui/pickers";
import { IOptionFilter } from "@kushki/frontend-molecules/filter-container";
import { connect } from "react-redux";
import FilterComponent from "../FilterComponent/FilterComponent";
import { DatePickerComponent } from "../DatePicker/DatePickerComponent";
import SearchText from "../SearchText/SearchText";

export interface IFilters {
  filterComponent: {
    filters: IOptionFilter[];
    onApplyFilter: (
      search: { filter: object; rangeAmount?: object },
      filters: IOptionFilter[]
    ) => void;
  };
  datePicker?: {
    handleDate?: (dateRange: DateRange<Date>) => void;
    dateValue?: DateRange<Date>;
  };
  isMobile: boolean;
  isLoading?: boolean;
  trxIdentifierFilter: {
    setTrxIdentifier: Dispatch<SetStateAction<string>>;
    trxIdentifier: string;
    handleSearchTrxIdentifier: () => void;
  };
}

export const Filters: React.FC<IFilters> = (props: IFilters) => {
  return (
    <React.Fragment>
      <Paper
        style={{
          paddingLeft: 12,
          paddingRight: 12,
          backgroundColor: "#F7FAFC",
          borderRadius: 4,
        }}
      >
        <Toolbar disableGutters>
          <Box display="flex" flexWrap="wrap" flexGrow={1} alignItems="center">
            <Box>
              <SearchText trxIdentifierFilter={props.trxIdentifierFilter} />
            </Box>
            <Box display="flex">
              <Box pr={1} pl={1}>
                <FilterComponent
                  onApplyFilter={props.filterComponent.onApplyFilter}
                  filters={props.filterComponent.filters}
                  isMobile={props.isMobile}
                />
              </Box>
              <Box>
                <DatePickerComponent
                  isLoading={props.isLoading}
                  handleDate={props.datePicker!.handleDate}
                  disableFuture
                  value={props.datePicker!.dateValue}
                />
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </Paper>
    </React.Fragment>
  );
};

export default connect(null, null)(Filters);
