/**
 * PaymentMethodEnum
 */

export enum OptionsEnum {
  CARD = "Tarjeta",
  CASH = "Efectivo",
  PAYOUTS_CARD = "Dispersión de tarjeta",
  PAYOUTS_CASH = "Efectivo",
  TRANSFER_SUBSCRIPTIONS = "Débitos recurrentes",
  TRANSFER = "Transferencia",
  PAYOUTS_TRANSFER = "Transferencia",
  SUBSCRIPTIONS = "Suscripciones",
  WEBCHECKOUT = "Smartlinks",
  VPOS = "Virtual POS",
  CARD_CHARGEBACK_VOID = "Devoluciones",
  CARD_CHARGEBACK = "Contracargo",
}

export enum WebhookEventsEnum {
  approvedTransaction = "approvedTransaction",
  chargeback = "chargeback",
  declinedTransaction = "declinedTransaction",
  failedRetry = "failedRetry",
  lastRetry = "lastRetry",
  subscriptionDelete = "subscriptionDelete",
  declinedSubscription = "declinedSubscription",
  subscriptionDeclined = "subscriptionDeclined",
  approvedSubscription = "approvedSubscription",
  subscriptionApproved = "subscriptionApproved",
  cashPreathorization = "cashPreathorization",
  chargebackInitialized = "initialized",
  chargebackPending = "pending",
  chargebackReview = "review",
  chargebackApproval = "approval",
  chargebackDeclined = "declined",
  chargebackFailed = "failed",
  chargebackExpired = "expired",
}

export enum WebhookServiceEnum {
  card = "card",
  cash = "cash",
  "payouts-card" = "payouts-card",
  "payouts-cash" = "payouts-cash",
  "transfer-subscriptions" = "transfer-subscriptions",
  transfer = "transfer",
  "payouts-transfer" = "payouts-transfer",
  subscriptions = "subscriptions",
  webcheckout = "webcheckout",
  vpos = "vpos",
  "card-chargeback" = "card-chargeback",
  "card-chargeback-void" = "card-chargeback-void",
}

export const TRANSACTION_STATUS: Record<WebhookEventsEnum, string> = {
  approvedTransaction: "Transacción aprobada",
  chargeback: "Contracargos",
  declinedTransaction: "Transacción declinada",
  failedRetry: "Intento fallido",
  lastRetry: "Último intento",
  subscriptionDelete: "Suscripción borrada",
  declinedSubscription: "Suscripción declinada",
  subscriptionDeclined: "Suscripción declinada",
  approvedSubscription: "Suscripción aprobada",
  subscriptionApproved: "Suscripción aprobada",
  cashPreathorization: "Preautorización",
  [WebhookEventsEnum.chargebackInitialized]: "Inicializado",
  [WebhookEventsEnum.chargebackReview]: "En revisión",
  [WebhookEventsEnum.chargebackPending]: "Documentación enviada",
  [WebhookEventsEnum.chargebackApproval]: "Ganado",
  [WebhookEventsEnum.chargebackDeclined]: "Perdido",
  [WebhookEventsEnum.chargebackFailed]: "Fallido",
  [WebhookEventsEnum.chargebackExpired]: "Vencido",
};

export const PAYMENT_METHODS_EVENTS: Record<
  WebhookServiceEnum,
  { [key: string]: string }
> = {
  card: {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  cash: {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
    cashPreathorization: TRANSACTION_STATUS.cashPreathorization,
  },
  subscriptions: {
    lastRetry: TRANSACTION_STATUS.lastRetry,
    failedRetry: TRANSACTION_STATUS.failedRetry,
    subscriptionDelete: TRANSACTION_STATUS.subscriptionDelete,
    subscriptionApproved: TRANSACTION_STATUS.subscriptionApproved,
    subscriptionDeclined: TRANSACTION_STATUS.subscriptionDeclined,
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  "payouts-card": {},
  "payouts-cash": {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  "transfer-subscriptions": {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
    approvedSubscription: TRANSACTION_STATUS.approvedSubscription,
    declinedSubscription: TRANSACTION_STATUS.declinedSubscription,
  },
  transfer: {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  "payouts-transfer": {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  webcheckout: {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  vpos: {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  "card-chargeback-void": {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  "card-chargeback": {
    approval: TRANSACTION_STATUS.approval,
    declined: TRANSACTION_STATUS.declined,
    expired: TRANSACTION_STATUS.expired,
    failed: TRANSACTION_STATUS.failed,
    initialized: TRANSACTION_STATUS.initialized,
    pending: TRANSACTION_STATUS.pending,
    review: TRANSACTION_STATUS.review,
  },
};

export const PAYMENT_METHODS: Record<string, string> = {
  card: OptionsEnum.CARD,
  cash: OptionsEnum.CASH,
  "payouts-cash": OptionsEnum.PAYOUTS_CASH,
  "transfer-subscriptions": OptionsEnum.TRANSFER_SUBSCRIPTIONS,
  transfer: OptionsEnum.TRANSFER,
  "payouts-transfer": OptionsEnum.PAYOUTS_TRANSFER,
};
export const PRODUCTS: Record<string, string> = {
  subscriptions: OptionsEnum.SUBSCRIPTIONS,
  webcheckout: OptionsEnum.WEBCHECKOUT,
  vpos: OptionsEnum.VPOS,
};

export const OTHERS: Record<string, string> = {
  "card-chargeback": OptionsEnum.CARD_CHARGEBACK_VOID,
  "card-chargeback-void": OptionsEnum.CARD_CHARGEBACK,
};

export const OPTIONS: Record<string, string> = {
  card: OptionsEnum.CARD,
  cash: OptionsEnum.CASH,
  "payouts-card": OptionsEnum.PAYOUTS_CARD,
  "payouts-cash": OptionsEnum.PAYOUTS_CASH,
  "transfer-subscriptions": OptionsEnum.TRANSFER_SUBSCRIPTIONS,
  transfer: OptionsEnum.TRANSFER,
  "payouts-transfer": OptionsEnum.PAYOUTS_TRANSFER,
  subscriptions: OptionsEnum.SUBSCRIPTIONS,
  webcheckout: OptionsEnum.WEBCHECKOUT,
  vpos: OptionsEnum.VPOS,
  "card-chargeback": OptionsEnum.CARD_CHARGEBACK,
  "card-chargeback-void": OptionsEnum.CARD_CHARGEBACK_VOID,
  general: "URL",
};

export const PAYIN_OPTIONS: Record<string, string> = {
  card: OptionsEnum.CARD,
  cash: OptionsEnum.CASH,
  transfer: OptionsEnum.TRANSFER,
  "transfer-subscriptions": OptionsEnum.TRANSFER_SUBSCRIPTIONS,
};

export const PAYOUTS_OPTIONS: Record<string, string> = {
  "payouts-cash": OptionsEnum.PAYOUTS_CASH,
  "payouts-transfer": OptionsEnum.PAYOUTS_TRANSFER,
};

export const PRODUCT_OPTIONS: Record<string, string> = {
  subscriptions: OptionsEnum.SUBSCRIPTIONS,
  webcheckout: OptionsEnum.WEBCHECKOUT,
  vpos: OptionsEnum.VPOS,
};

export const OTHER_OPTIONS: Record<string, string> = {
  "card-chargeback": OptionsEnum.CARD_CHARGEBACK,
  "card-chargeback-void": OptionsEnum.CARD_CHARGEBACK_VOID,
  general: "URL General",
};

export enum PAYMENT_METHOD_LABELS {
  PAYIN = "Recaudo de fondos",
  PAYOUTS = "Dispersión de dinero",
  PRODUCT = "Producto o servicio",
  OTHER = "Otros",
}

export type EventList = Record<
  WebhookServiceEnum | string,
  { [key: string]: string }
>;

export const PAYIN_EVENTS: EventList = {
  card: {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  cash: {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
    cashPreathorization: TRANSACTION_STATUS.cashPreathorization,
  },
  "transfer-subscriptions": {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
    approvedSubscription: TRANSACTION_STATUS.approvedSubscription,
    declinedSubscription: TRANSACTION_STATUS.declinedSubscription,
  },
  transfer: {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
};

export const PAYOUTS_EVENTS: EventList = {
  "payouts-cash": {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  "payouts-transfer": {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
};

export const PRODUCT_EVENTS: EventList = {
  subscriptions: {
    lastRetry: TRANSACTION_STATUS.lastRetry,
    failedRetry: TRANSACTION_STATUS.failedRetry,
    subscriptionDelete: TRANSACTION_STATUS.subscriptionDelete,
    subscriptionApproved: TRANSACTION_STATUS.subscriptionApproved,
    subscriptionDeclined: TRANSACTION_STATUS.subscriptionDeclined,
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  webcheckout: {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  vpos: {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
};

export const OTHER_EVENTS: EventList = {
  "card-chargeback": {
    initialized: TRANSACTION_STATUS.initialized,
    review: TRANSACTION_STATUS.review,
    // eslint-disable-next-line sort-keys
    pending: TRANSACTION_STATUS.pending,
    // eslint-disable-next-line sort-keys
    approval: TRANSACTION_STATUS.approval,
    declined: TRANSACTION_STATUS.declined,
    expired: TRANSACTION_STATUS.expired,
    failed: TRANSACTION_STATUS.failed,
  },
  "card-chargeback-void": {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
};

export enum ServiceOptionEnum {
  CARD = "card",
  CASH = "cash",
  TRANSFER = "transfer",
  TRANSFER_SUBSCRIPTIONS = "transfer-subscriptions",
  PAYOUTS_CARD = "payouts-card",
  PAYOUTS_CASH = "payouts-cash",
  PAYOUTS_TRANSFER = "payouts-transfer",
  CHARGEBACK = "card-chargeback",
  CHARGEBACK_VOID = "card-chargeback-void",
  WEBCHECKOUT = "webcheckout",
  VPOS = "vpos",
  SUBSCRIPTIONS = "subscriptions",
}

export const CARD_CHARGEBACK = {
  approval: "approval",
  declined: "declined",
  expired: "expired",
  failed: "failed",
  initialized: "initialized",
  pending: "pending",
  review: "review",
};
