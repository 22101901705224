import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DateRange } from "@material-ui/pickers/DateRangePicker/RangeTypes";
import { format, set, sub } from "date-fns";
import { IOptionFilter } from "@kushki/frontend-molecules/filter-container";
import { get, isEmpty, isUndefined } from "lodash";
import {
  ICatalog,
  TimeZoneCatalog,
} from "../../../shared/infrastructure/constants/TimeZoneCatalog";
import { NotificationsSearchRequest } from "../../../../types/query";
import { NotificationsIndexProps } from "../NotificationsIndex";
import { auth } from "../../../shared/auth";
import { useParams } from "react-router-dom";
import { IRangeAmount } from "@kushki/frontend-molecules/filter-range-amount";

export interface INotificationsIndexState {
  isAdmin: boolean;
  datePicker: {
    handleDate: (dateRange: DateRange<Date>) => void;
  };
  filterComponent: {
    filters: IOptionFilter[];
    onApplyFilter: (
      search: { filter: object; rangeAmount?: object },
      filters: IOptionFilter[],
      rangeFilter?: IRangeAmount
    ) => void;
  };
  handler: {
    handleChangePage: (
      _event: React.ChangeEvent<unknown>,
      value: number
    ) => void;
    handleChangePageSize: (value: number) => void;
  };
  state: LocalState;
  user_profile: payload;
  publicMerchantId: string;
  trxIdentifierFilter: {
    setTrxIdentifier: Dispatch<SetStateAction<string>>;
    trxIdentifier: string;
    handleSearchTrxIdentifier: () => void;
  };
}

export enum PaymentMethodEnum {
  WEBCHECKOUT = "webcheckout",
  VPOS = "vpos",
  TRANSFER = "transfer",
  PAY_OUTS_TRANSFER = "payouts-transfer",
  TRANSFER_SUBSCRIPTIONS = "transfer-subscriptions",
  CASH = "cash",
  PAYOUTS_CARD = "payouts-card",
  PAYOUTS_CASH = "payouts-cash",
  CARD = "card",
  SUBCRIPTIONS = "subscriptions",
  CARD_CHARGEBACK = "card-chargeback",
}

export enum LabelEnum {
  "webcheckout" = "Smartlink",
  "vpos" = "Virutal POS",
  "transfer" = "Transferencias de cobro",
  "payouts-transfer" = "Transferencias de pago",
  "transfer-subscriptions" = "Débito recurrente",
  "cash" = "Págos en efectivo",
  "payouts-card" = "Dispersión de tarjeta",
  "payouts-cash" = "Cobros en efectivo",
  "card" = "Tarjeta",
  "subscriptions" = "Subscripciones",
  "card-chargeback" = "Devoluciones",
}

export enum WebhookEventEnum {
  APPROVED_TRANSACTION = "approvedTransaction",
  DECLINED_TRANSACTION = "declinedTransaction",
  FAILED_RETRY = "failedRetry",
  LAST_RETRY = "lastRetry",
  DELETE_SUBSCRIPTION = "subscriptionDelete",
  SUCCESSFULL_CHARGE = "succesfullCharge",
  DECLINED_SUBSCRIPTION = "declinedSubscription|subscriptionDeclined",
  APPROVED_SUBSCRIPTION = "approvedSubscription|subscriptionApproved",
  DECLINED_CHARGE = "declinedCharge",
}

export const defaultFilters: IOptionFilter[] = [
  {
    id: "payment_method",
    title: "Medios de pago",
    options: [
      { key: PaymentMethodEnum.CARD, label: "Tarjeta", selected: false },
      {
        key: PaymentMethodEnum.CASH,
        label: "Pagos en efectivo",
        selected: false,
      },
      {
        key: PaymentMethodEnum.PAYOUTS_CASH,
        label: "Cobros en efectivo",
        selected: false,
      },
      {
        key: PaymentMethodEnum.PAY_OUTS_TRANSFER,
        label: "Transferencias de cobro",
        selected: false,
      },
      {
        key: PaymentMethodEnum.TRANSFER,
        label: "Transferencias de pago",
        selected: false,
      },
      {
        key: PaymentMethodEnum.TRANSFER_SUBSCRIPTIONS,
        label: "Débito recurrente",
        selected: false,
      },
      {
        key: PaymentMethodEnum.PAYOUTS_CARD,
        label: "Dispersión de tarjeta",
        selected: false,
      },
    ],
  },
  {
    id: "product_service",
    title: "Producto o servicio",
    options: [
      {
        key: PaymentMethodEnum.SUBCRIPTIONS,
        label: "Subscripciones",
        selected: false,
      },
      {
        key: PaymentMethodEnum.WEBCHECKOUT,
        label: "Smartlink",
        selected: false,
      },
      {
        key: PaymentMethodEnum.CARD_CHARGEBACK,
        label: "Devoluciones",
        selected: false,
      },
    ],
  },
  {
    id: "others",
    title: "Otros",
    options: [
      { key: PaymentMethodEnum.VPOS, label: "Virtual POS", selected: false },
    ],
  },
  {
    id: "event",
    title: "Eventos",
    options: [
      {
        key: WebhookEventEnum.APPROVED_TRANSACTION,
        label: "Transacción aprobada",
        selected: false,
      },
      {
        key: WebhookEventEnum.DECLINED_TRANSACTION,
        label: "Transacción declinada",
        selected: false,
      },
      {
        key: WebhookEventEnum.APPROVED_SUBSCRIPTION,
        label: "Subscripción aprobada",
        selected: false,
      },
      {
        key: WebhookEventEnum.DECLINED_SUBSCRIPTION,
        label: "Subscripción declinada",
        selected: false,
      },
      {
        key: WebhookEventEnum.DELETE_SUBSCRIPTION,
        label: "Subscripción borrada",
        selected: false,
      },
      {
        key: WebhookEventEnum.FAILED_RETRY,
        label: "Intento fallido",
        selected: false,
      },
    ],
  },
];

interface payload {
  name: string;
  familyName: string;
  userName: string;
  email: string;
  merchantId: string;
  group: string[];
  admin: boolean;
  userMaster: boolean;
  timeZone: string;
  timeZoneRegion: string;
}

const _timeZoneDefault: string = "America/Guayaquil";

export const timeZoneHour = (
  text: string | undefined,
  value: string
): string => {
  if (text === undefined) return value === "utc" ? "-05:00" : _timeZoneDefault;

  const index = TimeZoneCatalog.findIndex(
    (data: ICatalog) => data.description === text
  );

  if (index >= 0) {
    const lang: string | undefined = get(
      TimeZoneCatalog[index],
      value,
      undefined
    );

    return lang !== undefined
      ? lang
      : value === "utc"
      ? "-05:00"
      : _timeZoneDefault;
  }

  return value === "utc" ? "-05:00" : _timeZoneDefault;
};

interface LocalState {
  isLoading: boolean;
  pagination: {
    limit: number;
    page: number;
    total: number;
    isMobile: boolean;
    notificationsPerPage: number;
    currentPages: number;
    pages: number[];
  };
  search: NotificationsSearchRequest;
  dateValue: DateRange<Date>;
}

const getUserProfile = (): payload => {
  const payload = JSON.parse(localStorage.getItem("payload")!);

  return {
    name: get(payload, "name", ""),
    familyName: get(payload, "family_name", ""),
    userName: get(payload, "cognito:username", ""),
    email: get(payload, "email", ""),
    merchantId: payload.merchantId,
    group: get(payload, "cognito:groups", []),
    admin: get(
      JSON.parse(localStorage.getItem("roles")!),
      "BackofficeAdmin",
      false
    ),
    userMaster: get(
      JSON.parse(localStorage.getItem("roles")!),
      "BackofficeUserMaster",
      false
    ),
    timeZone: timeZoneHour(localStorage.getItem("timeZone")!, "utc"),
    timeZoneRegion: timeZoneHour(localStorage.getItem("timeZone")!, "region"),
  };
};

export const createPaymentMethodFilterString = (filter: {
  [key: string]: any;
}) => {
  let payment_method_result = filter.payment_method;
  if (filter.product_service && filter.product_service !== "")
    payment_method_result +=
      payment_method_result !== ""
        ? `|${filter.product_service}`
        : filter.product_service;
  if (filter.others && filter.others !== "")
    payment_method_result +=
      payment_method_result !== "" ? `|${filter.others}` : filter.others;
  return payment_method_result;
};

export const useNotificationsIndexState = (
  props: NotificationsIndexProps
): INotificationsIndexState => {
  const [trxIdentifier, setTrxIdentifier]: [
    string,
    Dispatch<SetStateAction<string>>
  ] = useState("");
  const user_profile = getUserProfile();
  const [state, setState] = useState<LocalState>({
    isLoading: true,
    search: {
      offset: 0,
      from: format(
        set(sub(new Date(), { days: 1 }), { hours: 0, minutes: 0, seconds: 0 }),
        "yyyy-MM-dd'T'HH:mm:ss"
      ),
      to: format(
        set(new Date(), { hours: 23, minutes: 59, seconds: 59 }),
        "yyyy-MM-dd'T'HH:mm:ss"
      ),
      timeZone: user_profile.timeZone,
      merchantId: user_profile.admin
        ? get(useParams(), "merchantId")
        : auth.publicMerchantId(),
      filter: {
        paymentMethod: "",
        event: "",
        transactionIdentifier: "",
      },
    },
    pagination: {
      limit: 5,
      page: 1,
      total: 0,
      isMobile: false,
      notificationsPerPage: 5,
      currentPages: 5,
      pages: [5, 10, 15, 20],
    },
    dateValue: [
      set(sub(new Date(), { days: 1 }), { hours: 0, minutes: 0, seconds: 0 }),
      set(new Date(), { hours: 23, minutes: 59, seconds: 59 }),
    ],
  });

  const handleChangePageSize = (value: number) => {
    setState({
      ...state,
      isLoading: true,
      pagination: {
        ...state.pagination,
        limit: value,
        currentPages: value,
        notificationsPerPage: value,
        page: 1,
      },
    });
    fetchData({
      ...state.search,
      offset: 0,
      limit: value,
    });
  };

  const handleDate = (date: DateRange<Date> | MouseEvent): void => {
    const [from, to] = date as [Date, Date];
    setState({
      ...state,
      dateValue: date as [Date, Date],
      search: {
        ...state.search,
        from: format(set(sub(from, {}), {}), "yyyy-MM-dd'T'HH:mm:ss"),
        to: format(set(sub(to, {}), {}), "yyyy-MM-dd'T'HH:mm:ss"),
      },
    });
    fetchData({
      ...state.search,
      ...(!isEmpty(trxIdentifier) && {
        filter: {
          ...state.search.filter,
          transactionIdentifier: trxIdentifier,
        },
      }),
      from: format(set(sub(from, {}), {}), "yyyy-MM-dd'T'HH:mm:ss"),
      to: format(set(sub(to, {}), {}), "yyyy-MM-dd'T'HH:mm:ss"),
      limit: state.pagination.limit,
    });
  };

  const handleSearchTrxIdentifier = (): void => {
    fetchData({ ...state.search, limit: state.pagination.limit, offset: 0 });
  };

  const handleSearchFilter = (filters: {
    filter: {
      [key: string]: any;
    };
  }) => {
    const paymentMethod = createPaymentMethodFilterString(filters.filter);
    setState({
      ...state,
      isLoading: true,
      search: {
        ...state.search,
        filter: {
          ...state.search.filter,
          paymentMethod,
          event: filters.filter.event,
        },
      },
    });
    fetchData({
      ...state.search,
      filter: {
        ...state.search.filter,
        paymentMethod,
        event: filters.filter.event,
      },
      limit: state.pagination.limit,
    });
  };

  const handleChangePage = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setState({
      ...state,
      isLoading: true,
      pagination: {
        ...state.pagination,
        page: value,
      },
      search: {
        ...state.search,
        offset: (value - 1) * state.pagination.currentPages,
      },
    });
    fetchData({
      ...state.search,
      offset: (value - 1) * state.pagination.currentPages,
      limit: state.pagination.limit,
    });
  };

  const fetchData = (body: NotificationsSearchRequest) => {
    props.getWebhookNotifications(body);
  };

  useEffect(() => {
    fetchData({
      ...state.search,
      limit: state.pagination.limit,
    });
  }, []);

  useEffect(() => {
    setState({
      ...state,
      isLoading: false,
      pagination: {
        ...state.pagination,
        total: get(props, "notificationsData.total", 0),
      },
    });
  }, [props.notificationsData]);

  useEffect(() => {
    if (!isUndefined(trxIdentifier)) {
      setState({
        ...state,
        search: {
          ...state.search,
          filter: {
            ...state.search.filter,
            transactionIdentifier: trxIdentifier,
          },
        },
      });
    }
  }, [trxIdentifier]);

  return {
    isAdmin: auth.isAdmin(),
    filterComponent: {
      onApplyFilter: handleSearchFilter,
      filters: defaultFilters,
    },
    datePicker: {
      handleDate,
    },
    handler: {
      handleChangePage,
      handleChangePageSize,
    },
    state,
    user_profile,
    publicMerchantId: get(state, "search.merchantId"),
    trxIdentifierFilter: {
      setTrxIdentifier,
      trxIdentifier,
      handleSearchTrxIdentifier,
    },
  };
};
