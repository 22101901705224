import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";

export const useStyles = makeStyles(() => ({
  inputSearchSubscriptions: {
    "& .MuiFormControl-root": {
      height: "auto",
    },
    "& > * + *": {
      marginTop: theme.spacing(0),
    },
    "& .MuiInputBase-root": {
      height: "auto",
      width: "auto",
      backgroundColor: "#FFFFFF",
    },
    "& .MuiInputBase-input": {
      textAlign: "left !important",
      width: 425,
      [theme.breakpoints.between(750, 1270)]: {
        maxWidth: "350px",
      },
      [theme.breakpoints.down(600)]: {
        maxWidth: "350px",
      },
    },
  },
  wrappedLabelXs: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "220px",
  },
}));
