import { useHistory } from "react-router-dom";
import { WebhookNotification } from "../../../../types/webhook_notification";
import { useMediaQuery } from "@material-ui/core";
import { get, isEmpty } from "lodash";
import {
  INotificationDetailState,
  NOTIFICATION_CONFIG_DEFAULT,
} from "./useNotificationDetailState.interfaces";
import { format, utcToZonedTime } from "date-fns-tz";
import { highlight, languages } from "prismjs";

const merchantInformation: string | null = !isEmpty(
  localStorage.getItem("merchantInfo")
)
  ? localStorage.getItem("merchantInfo")
  : localStorage.getItem("merchantBasicInformation");

const merchantInfo = JSON.parse(merchantInformation || "{}");

const useNotificationDetailState = (): INotificationDetailState => {
  const history = useHistory<WebhookNotification>();
  const isMobile: boolean = useMediaQuery("(max-width:767px)");
  const handleGoBack = (): void => {
    history.goBack();
  };

  const dateFormat = format(
    utcToZonedTime(
      history.location.state.timestamp,
      "UTC-05 (Bogota, Quito, Lima)"
    ),
    "dd-MM-yyyy HH:mm:ss"
  );
  const onHighLight = (code: any) =>
    highlight(
      code,
      languages.extend("javascript", {
        color: /\b(?:red|brown|blue)\b/,
      }),
      "javascript"
    );

  return {
    dateFormat,
    onHighLight,
    isMobile,
    select: {
      webhookNotification: {
        ...history.location.state,
        config: get(
          history.location.state,
          "source",
          NOTIFICATION_CONFIG_DEFAULT
        ),
      },
      merchantId: merchantInfo,
    },
    actions: {
      handleGoBack,
    },
  };
};

export default useNotificationDetailState;
