import React from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import { Tag } from "react-feather";
import { useSearchTextState } from "./state/useSearchTextState";
import { ISearchText } from "./SearchText.interfaces";
import { useStyles } from "./SearchText.styles";

export const SearchText: React.FC<ISearchText> = (props: ISearchText) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(600)
  );
  const isMdScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between(750, 1270)
  );

  const { handleKeyPress, handleSearchChange } = useSearchTextState(props);

  return (
    <Box>
      <TextField
        className={classes.inputSearchSubscriptions}
        size="small"
        margin="dense"
        id="outlined-search"
        label={"Busca por número de ticket o referencia de transacción"}
        onKeyDown={handleKeyPress}
        type="text"
        onChange={handleSearchChange}
        variant="outlined"
        value={props.trxIdentifierFilter.trxIdentifier}
        inputProps={{ maxLength: 50 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={props.trxIdentifierFilter.handleSearchTrxIdentifier}
                edge="end"
                disabled={props.trxIdentifierFilter.trxIdentifier.length === 0}
              >
                <Tag />
              </IconButton>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          className: isSmallScreen || isMdScreen ? classes.wrappedLabelXs : "",
        }}
      />
    </Box>
  );
};

export default SearchText;
